import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { VerificationStatus } from '../../types/status';
import { useStyles as useStatusStyles } from './status-styles';
import clsx from 'clsx';

type Props = {
  status: VerificationStatus;
};

const useStyles = makeStyles((theme) => ({
  verifiedIcon: {
    color: theme.palette.primary.main,
  },
}));

const QueuedStatus: React.FC<Props> = (props: Props) => {
  const { status } = props;
  const classes = useStyles();
  const statusClasses = useStatusStyles();
  return (
    <div className={statusClasses.root}>
      <CheckCircleIcon className={clsx(statusClasses.icon, classes.verifiedIcon)} />
      <div className={statusClasses.titleText}>Your Identity is verified!</div>
      <div className={statusClasses.helperText}>
        Please wait for admin to add your IP {status.userIp} to whitelist.{' '}
        {status.userEmail && (
          <span>
            You will receive a confirmation email ({status.userEmail}) when your ip is accessible to
            NTTData project.
          </span>
        )}
      </div>
    </div>
  );
};

export default QueuedStatus;
