import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { useStyles as useStatusStyles } from './status-styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.error.main,
  },
}));

const ErrorStatus: React.FC = () => {
  const classes = useStyles();
  const statusClasses = useStatusStyles();
  return (
    <div className={statusClasses.root}>
      <ErrorIcon className={clsx(statusClasses.icon, classes.errorIcon)} />
      <div className={statusClasses.titleText}>Invalid Verification</div>
    </div>
  );
};

export default ErrorStatus;
