import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from './components/App';
import { theme } from './styles/mui-theme';

const Root: React.FC = () => (
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>
);

export default Root;
