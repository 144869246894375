import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { VerificationStatus } from '../../types/status';
import { useStyles as useStatusStyles } from './status-styles';
import clsx from 'clsx';

type Props = {
  status: VerificationStatus;
};

const useStyles = makeStyles((theme) => ({
  verifiedIcon: {
    color: theme.palette.primary.main,
  },
}));

const VerifiedStatus: React.FC<Props> = (props: Props) => {
  const { status } = props;
  const classes = useStyles();
  const statusClasses = useStatusStyles();
  return (
    <div className={statusClasses.root}>
      <CheckCircleIcon className={clsx(statusClasses.icon, classes.verifiedIcon)} />
      <div className={statusClasses.titleText}>Your IP {status.userIp} is verified!</div>
      <div className={statusClasses.helperText}>You can use this IP to access NTTData project.</div>
    </div>
  );
};

export default VerifiedStatus;
