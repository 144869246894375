import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 30),
  },
  icon: {
    color: theme.palette.error.main,
    fontSize: 80,
    margin: theme.spacing(20, 0, 5, 0),
  },
  titleText: {
    color: theme.palette.text.primary,
    fontSize: 30,
    marginBottom: theme.spacing(5),
  },
  helperText: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    lineHeight: '30px',
  },
}));
