import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ErrorStatus from './ErrorStatus';
import FailedStatus from './FailedStatus';
import QueuedStatus from './QueuedStatus';
import VerifiedStatus from './VerifiedStatus';

import type { VerificationStatus } from '../../types/status';

const GET_STATUS_URL = '/verify/status';

const Status: React.FC = () => {
  const [queryToken, setQueryToken] = useState<string | null>();
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('qtk');
    setQueryToken(token);
    axios
      .get<VerificationStatus>(GET_STATUS_URL, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        params: { queryToken: token },
      })
      .then((res) => {
        if (res.status === 200 && res.data) {
          setVerificationStatus(res.data);
        }
      });
  }, []);

  if (!queryToken) return <ErrorStatus />;

  if (!verificationStatus) return null;

  switch (verificationStatus.status) {
    case 'FAILED':
      return <FailedStatus status={verificationStatus} />;
    case 'QUEUED':
      return <QueuedStatus status={verificationStatus} />;
    case 'VERIFIED':
      return <VerifiedStatus status={verificationStatus} />;
  }
};

export default Status;
