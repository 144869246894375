import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { VerificationStatus } from '../../types/status';
import { useStyles as useStatusStyles } from './status-styles';
import clsx from 'clsx';

type Props = {
  status: VerificationStatus;
};

const useStyles = makeStyles((theme) => ({
  failedIcon: {
    color: theme.palette.error.main,
  },
}));

const FailedStatus: React.FC<Props> = (props: Props) => {
  const { status } = props;
  const classes = useStyles();
  const statusClasses = useStatusStyles();
  return (
    <div className={statusClasses.root}>
      <ErrorIcon className={clsx(statusClasses.icon, classes.failedIcon)} />
      <div className={statusClasses.titleText}>Woops! Failed to verify your Identity.</div>
      <div className={statusClasses.helperText}>{status.message}</div>
    </div>
  );
};

export default FailedStatus;
