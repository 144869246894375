import React from 'react';
import Header from './header/Header';
import Status from './status/Status';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Status />
    </div>
  );
};

export default App;
