import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    border: Palette['primary'];
  }
  interface PaletteOptions {
    border: PaletteOptions['primary'];
  }
}

// material-ui theme overrides
const theme = createTheme({
  typography: {
    fontFamily: 'var(--font-primary)',
  },
  palette: {
    primary: {
      main: '#4ecdc4',
      light: '#8aded8',
      dark: '#2da199',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f5d76e',
    },
    error: {
      main: '#f95c69',
    },
    warning: {
      main: '#eb9532',
    },
    info: {
      main: '#19b5fe',
    },
    success: {
      main: '#1bbc9b',
    },
    text: {
      primary: '#393939',
      secondary: '#777777',
      disabled: '#b3b3b3',
      hint: '#bebebe',
    },
    border: {
      main: '#b5b5b5',
      light: '#cccccc',
      dark: '#7a7a7a',
    },
  },
});

export { theme };
