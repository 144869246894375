import React from 'react';
import logo from '../../images/logo.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '42px',
    background: 'white',
    borderBottom: '1px solid #d7d7d7',
    padding: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(0, 1),
  },
  logo: {
    height: '42px',
    '& img': {
      maxHeight: '100%',
    },
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <img src={logo} alt="MDAI logo" />
        </div>
      </div>
    </div>
  );
};

export default Header;
